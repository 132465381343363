import React, { Suspense, useEffect, useRef, useState, useMemo } from 'react'
import { DoubleSide, Vector2 } from 'three';
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { useGLTF, useTexture, Loader, Environment, useFBX, useAnimations } from '@react-three/drei';
import { MeshStandardMaterial } from 'three/src/materials/MeshStandardMaterial';

import { sRGBEncoding } from 'three/src/constants';
import ReactAudioPlayer from 'react-audio-player';

import createAnimation from './converter';
import blinkData from './blendDataBlink.json';

import * as THREE from 'three';
import axios from 'axios';

import { useAudioRecorder } from 'react-audio-voice-recorder';

import './App.css';

/**
 * Lodash library, providing utility functions for common programming tasks.
 * @module _
 */
const _ = require('lodash');

/**
 * The backend URL for the application, retrieved from the environment variables.
 * @constant {string} host
 */
const host = process.env.REACT_APP_BACKEND_URL;

/**
 * Avatar component that renders a 3D avatar model with various textures and animations.
 * 
 * @param {Object} props - The properties object.
 * @param {string} props.avatar_url - The URL of the avatar model.
 * @param {boolean} props.speak - Flag to trigger speech animation.
 * @param {Function} props.setSpeak - Function to set the speak state.
 * @param {string} props.audio - Audio data for speech.
 * @param {Function} props.resetAudio - Function to reset the audio data.
 * @param {string} props.text - Text data for speech.
 * @param {Function} props.setText - Function to set the text data.
 * @param {Function} props.callbackTextSent - Callback function when text is sent.
 * @param {Function} props.setAudioSource - Function to set the audio source.
 * @param {boolean} props.playing - Flag to trigger animation playback.
 * @param {Function} props.setIsResponding - Function to set the responding state.
 * 
 * @returns {JSX.Element} The Avatar component.
 */
function Avatar({ avatar_url, speak, setSpeak, audio, resetAudio, text, setText, callbackTextSent, setAudioSource, playing, setIsResponding, updateSubtitles }) {

  let model = useGLTF(avatar_url);
  let morphTargetDictionaryBody = null;
  let morphTargetDictionaryLowerTeeth = null;
  const [history, setIshistory] = useState(true);

  const [
    hairNormalText,
    teethLowerColor,
    teethNormal,
    teethRoughness,
    bodyBase,
    bodyNormal,
    bodyRoughness,
    bodySpecular,
    eyesTexture,
    tshirtDiffuseTexture
  ] = useTexture([
    "/images/textures/Zachary.001_hair2Haircards_normal.png",
    "/images/textures/Zachary.001_lower_teeth_base_color.png",
    "/images/textures/Zachary.001_upper_teeth_normal.png",
    "/images/textures/Zachary.001_lower_teeth_roughness.png",
    "/images/textures/Zachary.001_body_base_color.png",
    "/images/textures/Zachary.001_body_normal.png",
    "/images/textures/Zachary.001_body_roughness.png",
    "/images/textures/Zachary.001_body_specular.png",
    "/images/textures/Zachary.001_eyes_base_color.png",
    "/images/textures/Zachary.001_HG_TSHIRT_Male.002_base_color.png"
  ]);

  _.each([
    hairNormalText,
    teethLowerColor,
    teethNormal,
    teethRoughness,
    bodyBase,
    bodyNormal,
    bodyRoughness,
    bodySpecular,
    eyesTexture,
    tshirtDiffuseTexture
  ], t => {
    // console.log(t)
    t.encoding = sRGBEncoding;
    t.flipY = false;
  });

  // Luce Ambientale - Illumina la scena in modo uniforme
  // const ambientLight = new THREE.AmbientLight(0xffffff, 0.6); // Illuminazione diffusa
  // model.scene.add(ambientLight);

 //  Luce Puntuale - Focalizzata sull'avatar
  // const pointLight = new THREE.PointLight(0xffffff, 1, 100);
  // pointLight.position.set(0, 3, 5); // Posizionata di fronte all'avatar
  // pointLight.castShadow = true; // Abilita ombre
  // model.scene.add(pointLight);

  // Luce Posteriore - Aggiunge profondità all'avatar con un contorno
  // const backLight = new THREE.PointLight(0xffffff, 0.8, 100);
  // backLight.position.set(0, 3, -5); // Dietro l'avatar
  // model.scene.add(backLight);

  // pointLight.shadow.mapSize.width = 2048;
  // pointLight.shadow.mapSize.height = 2048;


  model.scene.traverse(node => {
    if (node.isMesh || node.isLineSegments || node.isSkinnedMesh) {
      console.log(node);

      if (node.isMesh) {
        node.geometry.computeVertexNormals(); // Ricalcola le normali
      }

      if (node.isMesh && !node.material) {
        console.warn(`Nodo senza materiale: ${node.name}`);
        node.material = new THREE.MeshStandardMaterial({ color: 0xcccccc }); // Materiale di default
      }

      node.castShadow = true;
      node.receiveShadow = true;
      node.frustumCulled = false;

      // Occhi
      if (node.name.includes("HG_Eyes001")) {
        node.material = new MeshStandardMaterial({
          map: eyesTexture,
          envMapIntensity: 1,
        });
      }

      // Capelli
      if (node.name.includes("Haircards")) {
        node.material.transparent = true;
        node.material.side = DoubleSide;
        node.material.color.setHex(0xfbe7a1);
        node.material.envMapIntensity = 1;
        node.material.opacity = 0.9;
        node.material.alphaTest = 0.5;
        node.material.roughness = 0.4;
        node.material.metalness = 0.1;
        node.material.envMapIntensity = 2;
      }

      // Corpo
      if (node.name.includes("HG_Body008")) {
        node.material.color.setHex(0xF5D7B6);
        node.material.envMapIntensity = 1.2;
        node.material.normalScale = new Vector2(0.6, 0.6);
        // Aggiungi le texture necessarie
        if (bodyBase) node.material.map = bodyBase;
        if (bodyNormal) node.material.normalMap = bodyNormal;

        morphTargetDictionaryBody = node.morphTargetDictionary;
      }

      // Denti inferiori
      if (node.name.includes("HG_TeethLower001")) {
        node.material = new MeshStandardMaterial({
          map: teethLowerColor,
          normalMap: teethNormal,
          roughnessMap: teethRoughness,
        });
        morphTargetDictionaryLowerTeeth = node.morphTargetDictionary;
      }

      if (node.name.includes("HG_TSHIRT001")) {
        node.material = new THREE.MeshStandardMaterial({
          color: 0x3498db, // Colore blu
          roughness: 0.5, // Modifica l'opacità
          metalness: 0.1, // Leggero effetto metallico
        });
      }

      // if (node.name.includes("HG_Flannel001")) {
      //   node.material = new THREE.MeshStandardMaterial({
      //     color: 0x415E9A, // Colore blu
      //     roughness: 0.5, // Modifica l'opacità
      //   });
      // }
    }
  });


  const [clips, setClips] = useState([]);
  const mixer = useMemo(() => new THREE.AnimationMixer(model.scene), []);

  useEffect(() => {
    (async () => {
      if (speak === false)
        return;

      let response;
      if (audio) {
        if (history) {
          response = await makeSpeech(audio, true);
          setIshistory(false)
        } else {
          response = await makeSpeech(audio, false);
        }

        resetAudio()
      }
      else if (text) {
        if (history) {
          response = await makeSpeechFromText(text, true)
          setText('')
          callbackTextSent(false)
          setIshistory(false)
        } else {
          response = await makeSpeechFromText(text, false)
          setText('')
          callbackTextSent(false)
        }
      }

      startAnimation(response.data)
      updateSubtitles(response.data.text);
    })();
  }, [speak]);

  function startAnimation(data) {
    let { blendData, filename } = data;

    console.log(morphTargetDictionaryBody);

    let newClips = [
      createAnimation(blendData, morphTargetDictionaryBody, 'HG_Body008'),
      createAnimation(blendData, morphTargetDictionaryLowerTeeth, 'HG_TeethLower001')];

    filename = host + filename;

    setClips(newClips);
    setAudioSource(filename);
  }

  let idleFbx = useFBX('/idle.fbx');
  let { clips: idleClips } = useAnimations(idleFbx.animations);

  idleClips[0].tracks = _.filter(idleClips[0].tracks, track => {
    return track.name.includes("Head") || track.name.includes("Neck") || track.name.includes("Spine2");
  });

  idleClips[0].tracks = _.map(idleClips[0].tracks, track => {

    if (track.name.includes("Head")) {
      track.name = "head.quaternion";
    }

    if (track.name.includes("Neck")) {
      track.name = "neck.quaternion";
    }

    if (track.name.includes("Spine")) {
      track.name = "spine2.quaternion";
    }

    return track;

  });

  useEffect(() => {
    let blinkClip = createAnimation(blinkData, morphTargetDictionaryBody, 'HG_Body008');
    let blinkAction = mixer.clipAction(blinkClip);
    blinkAction.play();

  }, []);

  // Play animation clips when available
  useEffect(() => {

    if (playing === false)
      return;

    _.each(clips, clip => {
      let clipAction = mixer.clipAction(clip);
      clipAction.setLoop(THREE.LoopOnce);
      clipAction.play();

    });

  }, [playing]);
  const modelRef = useRef();

  useFrame((state, delta) => {
    mixer.update(delta);
  });


  return (
    <group name="avatar">
      <primitive ref={modelRef}
        object={model.scene}
        dispose={null}
        position={[0, 0.40, 0.20]}
      />
    </group>
  );
}


/**
 * Sends an audio blob and history data to the server for speech processing.
 *
 * @param {Blob} blob - The audio blob to be sent.
 * @param {string} history - The history data to be sent.
 * @returns {Promise} - A promise that resolves with the server response.
 */
async function makeSpeech(blob, history) {
  const formData = new FormData();
  formData.append('audio', blob);
  formData.append('history', history);
  return axios.post(host + '/talk-sts', formData);
}

/**
 * Sends a POST request to generate speech from text.
 *
 * @param {string} text - The text to be converted to speech.
 * @param {Array} history - An array representing the history of previous texts.
 * @returns {Promise} - A promise that resolves with the response from the server.
 */
async function makeSpeechFromText(text, history) {
  return await axios.post(host + '/talk-tts', { text, history: history });
}

/**
 * The main application component.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * return <App />;
 *
 * @description
 * This component handles the main functionality of the application, including:
 * - Managing audio recording and playback using `useAudioRecorder` and `ReactAudioPlayer`.
 * - Handling user input through a text area and buttons.
 * - Sending text input to the backend.
 * - Managing various states such as `speak`, `text`, `sending`, `audioSource`, `playing`, `blob`, and `isResponding`.
 * - Rendering a 3D scene using `Canvas` and `Scene` components.
 *
 * @property {Object} audioPlayer - A reference to the audio player element.
 * @property {boolean} speak - State to manage if the application should speak.
 * @property {string} text - State to manage the text input from the user.
 * @property {boolean} sending - State to manage if the text is being sent to the backend.
 * @property {string|null} audioSource - State to manage the source of the audio to be played.
 * @property {boolean} playing - State to manage if the audio is currently playing.
 * @property {Blob|null} blob - State to manage the recorded audio blob.
 * @property {boolean} isResponding - State to manage if the application is responding.
 * @property {Object} options - Options for the audio recorder.
 * @property {Function} startRecording - Function to start recording audio.
 * @property {Function} stopRecording - Function to stop recording audio.
 * @property {Function} togglePauseResume - Function to toggle pause/resume recording.
 * @property {Blob|null} recordingBlob - The recorded audio blob.
 * @property {boolean} isRecording - State to manage if the application is recording audio.
 * @property {boolean} isPaused - State to manage if the recording is paused.
 * @property {number} recordingTime - The duration of the recording.
 * @property {Object} mediaRecorde - The media recorder instance.
 * @property {Function} playerEnded - Function to handle the end of audio playback.
 * @property {Function} playerReady - Function to handle when the audio player is ready.
 * @property {Function} sendTextToBackend - Function to send the text input to the backend.
 */


function App() {

  const audioPlayer = useRef();

  const [speak, setSpeak] = useState(false);
  const [text, setText] = useState("");
  const [sending, setSending] = useState(false)
  const [audioSource, setAudioSource] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [blob, setBlob] = useState(null);
  const [isResponding, setIsResponding] = useState(false);
  const [showSubtitles, setShowSubtitles] = useState(false);  // Stato per mostrare/nascondere il box
  const [subtitles, setSubtitles] = useState("");  // Stato per i sottotitoli
  const [fullSubtitles, setFullSubtitles] = useState(""); // Testo completo dei sottotitoli

  // Funzione per gestire la chiusura e apertura del box
  const toggleSubtitles = () => {
    setShowSubtitles(!showSubtitles);
  };

  const updateSubtitles = (text) => {
    setShowSubtitles(true);
    setFullSubtitles(text); // Salva l'intero testo dei sottotitoli
    setSubtitles(""); // Resetta i sottotitoli correnti
  };

  // Funzione per sincronizzare i sottotitoli con l'audio
  const syncSubtitles = (subtitlesText, duration) => {
    const words = subtitlesText.split(' ');  // Dividi il testo in parole
    const interval = duration / words.length;  // Calcola quanto tempo deve durare ogni parola

    let currentWordIndex = 0;
    setSubtitles("");  // Inizia con un testo vuoto

    const intervalId = setInterval(() => {
      if (currentWordIndex < words.length) {
        const currentWord = words[currentWordIndex];
        if (currentWord) { // Controlla che la parola non sia undefined o vuota
          setSubtitles((prevSubtitles) => prevSubtitles + " " + currentWord);
        }
        currentWordIndex++;
      } else {
        clearInterval(intervalId);  // Ferma l'intervallo quando tutte le parole sono state mostrate
      }
    }, interval * 1000);  // Aggiorna i sottotitoli a intervalli regolari
  };


  useEffect(() => {
    if (audioSource && playing) {
      const audioDuration = audioPlayer.current.audioEl.current.duration; // Ottieni la durata dell'audio
      syncSubtitles(fullSubtitles, audioDuration);  // Sincronizza i sottotitoli con l'audio
    }
  }, [audioSource, playing]);  // Aggiorna quando l'audio o lo stato di riproduzione cambia


  const options = { downloadFileExtension: 'wav' }
  const { startRecording, stopRecording, togglePauseResume, recordingBlob, isRecording, isPaused, recordingTime, mediaRecorde }
    = useAudioRecorder(options);

  useEffect(() => {
    if (recordingBlob) {
      setBlob(recordingBlob);
      setSpeak(true);
      setShowSubtitles(false);
      setSubtitles("");
      setSending(true);

    }
  }, [recordingBlob]);

  //  // Effetto per aggiornare i sottotitoli quando cambia l'audio o inizia la riproduzione
  //  useEffect(() => {
  //   if (audioSource && playing) {
  //     setShowSubtitles(true);
  //     setSubtitles("Questi sono i sottotitoli sincronizzati con l'audio.");  // Sottotitoli dinamici qui
  //   }
  // }, [audioSource, playing]);  // Aggiorna quando cambia l'audio o lo stato di riproduzione

  // End of play
  /**
   * Handles the event when the player has ended.
   *
   * @param {Event} e - The event object.
   */
  function playerEnded(e) {
    setAudioSource(null);
    setSpeak(false);
    setPlaying(false);
    setSending(false);
    // setShowSubtitles(false);
    // setSubtitles("");
  }

  // Player is ready
  /**
   * Handles the event when the player is ready.
   *
   * @param {Event} e - The event object.
   */
  function playerReady(e) {
    audioPlayer.current.audioEl.current.play();
    setPlaying(true);
  }

  /**
   * Initiates the process of sending text to the backend and sets the state to indicate that the text is being sent and spoken.
   * 
   * @function
   * @name sendTextToBackend
   * @returns {void}
   */
  function sendTextToBackend() {
    setSending(true)
    setSpeak(true)
  }

  return (
    <div className="full">
      <div className='inputs-container'>
        <div className='text-area-wrapper'>
          <textarea
            className='text-area-element'
            placeholder='Type a question...'
            rows={1}
            value={text}
            onChange={(e) => setText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && text !== '') {
                e.preventDefault();
                sendTextToBackend();
              }
            }}
          />

          {/* Pulsante di invio */}
          {sending ? (
            <></>
          ) : (
            <button className={`text-area-button ${text === '' ? 'text-area-button-disabled' : ''}`} onClick={sendTextToBackend} disabled={text === ''}>
              <i className="fas fa-paper-plane" />  {/* Icona di invio */}
            </button>
          )}

          {/* Pulsante di registrazione con icona Play/Pause */}
          {isRecording ? (
            <button onClick={stopRecording} className='record-button'>
              <i className="fas fa-stop" />  {/* Icona di pausa */}
            </button>
          ) : (
            sending ? (
              <button className='record-button' disabled>
                <i className="fas fa-spinner fa-spin" />  {/* Mostra lo spinner */}
              </button>
            ) : (
              <button onClick={startRecording} className='record-button'>
                <i className="fas fa-microphone" />  {/* Icona di play */}
              </button>
            )
          )}
        </div>
      </div>

      {/* Box per i sottotitoli */}
      {showSubtitles && (
        <div className="subtitles-box">
          {subtitles}
          {/* Pulsante per chiudere il box dei sottotitoli */}
          <button className="toggle-subtitles-button" onClick={toggleSubtitles}>
            {showSubtitles ? <i className="fas fa-times" /> : <i className="fas fa-chevron-down" />} {/* Icona di chiusura/apertura */}
          </button>
        </div>
      )}

      <ReactAudioPlayer
        src={audioSource}
        ref={audioPlayer}
        onEnded={playerEnded}
        onCanPlayThrough={playerReady}

      />

      {/* <Stats /> */}
      <Canvas dpr={2} onCreated={(ctx) => {
        ctx.gl.physicallyCorrectLights = true;
      }}
      >
        <Scene
          speak={speak}
          setSpeak={setSpeak}
          blob={blob}
          setBlob={setBlob}
          text={text}
          setText={setText}
          setSending={setSending}
          setAudioSource={setAudioSource}
          playing={playing}
          setIsResponding={setIsResponding}
          updateSubtitles={updateSubtitles}
        />
      </Canvas>
      <Loader dataInterpolation={(p) => `Loading... please wait`} />
    </div>
  )
}


/**
 * Bg component renders a background mesh with a texture.
 * 
 * This component uses the `useThree` hook to access the camera and viewport
 * from the Three.js context. It also uses the `useTexture` hook to load a texture
 * from a specified URL.
 * 
 * The component calculates the aspect ratio of the texture and adjusts the scale
 * of the mesh accordingly to maintain the aspect ratio. The position and rotation
 * of the mesh are also set based on the camera's properties.
 * 
 * @returns {JSX.Element|null} A mesh element with the background texture or null if the texture is not loaded.
 */
function Bg() {
  const { camera, viewport } = useThree();
  const planeRef = useRef(null);

  const texture = useTexture('/images/IMG_5337.jpg');

  if (!texture.image) {
    return null;
  }

  const textureAspect = texture.image.width / texture.image.height;
  const vFov = camera.fov * (Math.PI / 180);
  const planeHeight = 2 * Math.tan(vFov / 2) * Math.abs(camera.position.z);
  const planeWidth = planeHeight * viewport.aspect;
  const scale_d = 320
  const scale = planeWidth / planeHeight < textureAspect ?
    [planeHeight * textureAspect * scale_d, planeHeight * scale_d, 1] :
    [planeWidth * scale_d, (planeWidth / textureAspect) * scale_d, 1];
  const position = [0, -46, camera.far / 2 * -1];
  const rotation = [-Math.PI / 20, 0, 0];

  return (
    <mesh position={position} scale={scale} ref={planeRef} rotation={rotation}>
      <planeBufferGeometry args={[1, 1]} />
      <meshBasicMaterial map={texture} attach="material" />
    </mesh>
  );

}

/**
 * Scene component that sets up the 3D environment and renders the avatar.
 *
 * @param {Object} props - The properties object.
 * @param {boolean} props.speak - Indicates if the avatar is speaking.
 * @param {Function} props.setSpeak - Function to set the speak state.
 * @param {Blob} props.blob - Audio blob for the avatar.
 * @param {Function} props.setBlob - Function to set the audio blob.
 * @param {string} props.text - Text to be spoken by the avatar.
 * @param {Function} props.setText - Function to set the text.
 * @param {Function} props.setSending - Callback function when text is sent.
 * @param {Function} props.setAudioSource - Function to set the audio source.
 * @param {boolean} props.playing - Indicates if the audio is playing.
 * @param {Function} props.setIsResponding - Function to set the responding state.
 *
 * @returns {JSX.Element} The Scene component.
 */
function Scene({ speak, setSpeak, blob, setBlob, text, setText, setSending, setAudioSource, playing, setIsResponding, updateSubtitles }) {
  const { viewport, camera } = useThree();

  useEffect(() => {
    if (camera.isPerspectiveCamera) {
      camera.fov = 100;
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.near = 0.1;
      camera.far = 1000;
      camera.zoom = 1.8;
      camera.position.set(0, 2, 1);
      camera.updateProjectionMatrix();
      camera.lookAt(new THREE.Vector3(0, 1.65, -1))
    }
  }, [camera]);

  return (
    <>

      {/*<FloorPlane/>*/}
      <Suspense fallback={null}>
        <Environment background={false} files="/images/photo_studio_loft_hall_1k.hdr" />
      </Suspense>

      <Suspense fallback={null}>
        <Bg />
      </Suspense>

      <Suspense fallback={null}>
        <Avatar
          avatar_url="/export_v3.glb"
          speak={speak}
          setSpeak={setSpeak}
          audio={blob}
          resetAudio={() => setBlob(null)}
          text={text}
          setText={setText}
          callbackTextSent={setSending}
          setAudioSource={setAudioSource}
          playing={playing}
          setIsResponding={setIsResponding}
          updateSubtitles={updateSubtitles}
        />
      </Suspense>
    </>
  )
}

export default App;
